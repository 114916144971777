body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.glider-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  padding: 2rem;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-image-slice: 1;
  animation-name: showbordercontainer;
  animation-duration: 800ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 3.3s;

}

.circle-wrapper {
  display: flex;
  gap: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(0.25turn, rgba(0,0,0,0), rgba(255,255,255,0), rgba(0,0,0,0));
  border-image-slice: 1;
  animation-delay: 3s;
  animation-name: showborder;
  animation-duration: 800ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transform: translateY(-100vh);
  animation: fall 1s ease-out forwards;
  color: black;
}

.empty-circle {
  width: 50px;
  height: 50px;
}

.glider-container .circle-wrapper:nth-child(3) .circle:nth-child(1) {
  animation-delay: 0.3s, 3s;
  animation-name: fall, glow;
  animation-duration: 900ms, 0.4s;
  animation-timing-function: ease-out, ease-in;
  animation-iteration-count: 1, 2;
}

.glider-container .circle-wrapper:nth-child(3) .circle:nth-child(2) {
  animation-delay: 0.5s, 3.1s;
  animation-name: fall, glow;
  animation-duration: 900ms, 0.4s;
  animation-timing-function: ease-out, ease-in;
  animation-iteration-count: 1, 2;
}

.glider-container .circle-wrapper:nth-child(3) .circle:nth-child(3) {
  animation-delay: 0.7s, 3.15s;
  animation-name: fall, glow;
  animation-duration: 900ms, 0.4s;
  animation-timing-function: ease-out, ease-in;
  animation-iteration-count: 1, 2;
}

.glider-container .circle-wrapper:nth-child(2) .circle {
  animation-delay: 0.9s, 3.175s;
  animation-name: fall, glow;
  animation-duration: 900ms, 0.4s;
  animation-timing-function: ease-out, ease-in;
  animation-iteration-count: 1, 2;
}

.glider-container .circle-wrapper:nth-child(1) .circle {
  animation-delay: 1.2s, 3.22s;
  animation-name: fall, glow;
  animation-duration: 900ms, 0.4s;
  animation-timing-function: ease-out, ease-in;
  animation-iteration-count: 1, 2;
}




@keyframes fall {
  0% {
    transform: translateY(-100vh);
    filter: blur(50px);
  }
  100% {
    transform: translateY(0);
    filter: blur(0);
  }
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 0px #ffffff, 0 0 0px #ffffff;
    filter: none;
  }
  50% {
    box-shadow: 0 0 15px #ffffff, 0 0 20px #ffffff, 0 0 25px #ffffff;
  }
}

@keyframes showborder {
  0% {
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, rgba(0,0,0,0), rgba(255,255,255,0), rgba(0,0,0,0));
    border-image-slice: 1;
    filter: blur(0);
  }
  50% {
    filter: blur(50px);
    border-image: linear-gradient(0.25turn, rgba(0,0,0,0), rgba(255,255,255,0), rgba(0,0,0,0));

  }
  100% {
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, rgba(0,0,0,1), rgba(255,255,255,1), rgba(0,0,0,1));
    border-image-slice: 1;
    filter: blur(0);
  }
}

@keyframes showbordercontainer {
  0% {
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0turn, rgba(0,0,0,0), rgba(255,255,255,0), rgba(0,0,0,0));
    border-image-slice: 1;
  }
  50% {
    border-image: linear-gradient(0turn, rgba(0,0,0,0), rgba(255,255,255,0), rgba(0,0,0,0));
  }
  100% {
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0turn, rgba(0,0,0,1), rgba(255,255,255,1), rgba(0,0,0,1));
    border-image-slice: 1;
  }
}